export const types = {
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
  GET_LOGGED_USER_INFO: 'GET_LOGGED_USER_INFO',
}

export const logIn = (data) => {
  return {
    type: types.LOGGED_IN,
    payload: data
  }

}

export const logOut = () => {
  return {
    type: types.LOGGED_OUT,
  }
}

export const getLoggedUserInfo = (user_info) =>{
  return {
    type: types.GET_LOGGED_USER_INFO,
    payload: user_info
  }
}