const lowerCaseValidation = new RegExp("^(?=.*[a-z])");
const upperCaseValidation = new RegExp("^(?=.*[A-Z])");
const numberValidation = new RegExp("^(?=.*[0-9])");
const symbolValidation = new RegExp('(?=.*[!@$_%&*-,.?:])');
const validate = (values) => {
    let errors = {};
    if (!values.password) {
        errors.password = 'Password is required';
    } else if (values.password.length < 8) {
        errors.password = 'Min. 8 chars';
    } else if (!lowerCaseValidation.test(values.password)){
        errors.password = 'At least 1 lowercase char';
    }else if (!upperCaseValidation.test(values.password)){
        errors.password = 'At least 1 uppercase char';
    }else if (!numberValidation.test(values.password)){
        errors.password = 'At least 1 number';
    }else if (!symbolValidation.test(values.password)){
        errors.password = 'At least 1 valid symbol (!@$_%&*,.?:)';
    }
    return errors;
};

export default validate