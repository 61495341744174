import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import ThunkMiddleware  from 'redux-thunk';
import logger from 'redux-logger';

import rootReducer from './reducers/rootReducer'

const persistConfig = {
  key: 'main',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const configureStore = () => {
  let store = createStore(persistedReducer,
                          compose(
                            applyMiddleware(ThunkMiddleware),//logger
                            //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                          )
  )     
  let persistor = persistStore(store)
  return { store, persistor}
}

export default configureStore