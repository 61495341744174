import React from 'react';
import { Navbar, Button } from 'react-bootstrap';
import img01 from '../images/img01.png';
import { Link } from 'react-router-dom';
import { auth } from '../_redux/actions/';
import { connect } from 'react-redux';

const CustomNavBar = (props) => {
  const closeSession = () => {
    props.logOut()
  }
  return (
    <nav>
      <Navbar bg="dark" className="fixed-top">
        <Navbar.Brand>
          <Link to='/'>
            <img
              src={img01}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Logo"
            />
            <span className="text-white ml-2">
              ese+ connect
            </span>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {/* Update password - avilable to all users */}
          {
            props.isAuthenticated &&
            <Link to='/password_update'>
              <Button variant="outline-light" className="border-0">
                <i className="fa fa-lock pr-2" aria-hidden="true"></i>
                Update password
              </Button>
            </Link>
          }
          {/* Button to administation */}
          {
            props.auth.is_staff &&
            <Link to='/admin'>
              <Button variant="outline-light" className="border-0">
                <i className="fa fa-cogs pr-2" aria-hidden="true"></i>
                Admin
              </Button>
            </Link>
          }
          {/* Button to logout */}
          {
            props.isAuthenticated &&
            <Button variant="outline-light" className="border-0" onClick={closeSession}>
              <i className="fa fa-sign-out pr-2" aria-hidden="true"></i>
              Log out
            </Button>
          }
        </Navbar.Collapse>
      </Navbar>
    </nav>
  )
}
const mapDispatchToProps = {
  logOut: auth.logOut
};
export default connect(null, mapDispatchToProps)(CustomNavBar)