import React from "react";
import { useEffect } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { Card, Container, Row, Col, Image, Badge } from 'react-bootstrap';
import eSchool from '../images/e-school_logo.png';
import docenteSoy from '../images/LOGO-ESE-HIGH.png';
import eseplus from '../images/eseplus.png';
//Animations
const Box = posed.div({
  hoverable: true,
  pressable: true,
  init: {
      scale: 1,
      boxShadow: '0px 0px 0px rgba(0,0,0,0)'
  },
  hover: {
      scale: 1.3,
      boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
  },
  press: {
      scale: 1.1,
      boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
  }
});

const AnimatedCard = posed.div({
  enter: {
      y: 0,
      opacity: 1,
      delay: 100,
      delayChildren: 200,
      transition: {
          y: { type: 'spring', stiffness: 250, damping: 10 },
          default: { duration: 500 }
      }
  },
  exit: {
      y: 50,
      opacity: 0,
      transition: { duration: 150 }
  }
});

const ServicesPanel = (props) => {
  useEffect(() => {
      if (props.hasUser) {
          props.handleIsVisible(true)
      }
  },[])
  return (
      <div className="limiter">
          <div className="container-login100">
              <PoseGroup>
                  {props.isVisible && [
                      <AnimatedCard  key="animatedCard">
                          <Card style={{ width: '56rem', padding: '2rem 3rem' }}>
                              <Card.Body >
                                  <Card.Title className="text-center"> <h1>{props.title}</h1> </Card.Title>
                                  <Card.Text style={{ fontSize: '1.6rem' }} className="text-center">
                                      {props.subTitle}
                                  </Card.Text>
                              </Card.Body>
                              <Card.Body>
                                  <Container>
                                      <Row className="text-center">
                                          <Col xs={6} md={4}>
                                              <Box className="icon-site" key="box-1">
                                                  <a href="http://ese.school">
                                                      <Image src={eSchool} width="200px" />
                                                      <h4 key="schoolName-1">
                                                          <Badge variant="secondary"> e-school </Badge>
                                                      </h4>
                                                  </a>
                                              </Box>
                                          </Col>
                                          <Col xs={6} md={4}>
                                              <Box className="icon-site" key="box-2">
                                                  <a href="http://docente.soy">
                                                      <Image src={docenteSoy} rounded height="60px" style={{ backgroundColor: '#d6d6d6' }} />
                                                      <h4>
                                                          <Badge variant="secondary"> docente.soy </Badge>
                                                      </h4>
                                                  </a>
                                              </Box>
                                          </Col>
                                          <Col xs={6} md={4}>
                                              <Box className="icon-site" key="box-3">
                                                  <a href="http://ese.plus">
                                                      <Image src={eseplus} rounded height="60px" style={{ backgroundColor: '#502577' }} />
                                                      <h4>
                                                          <Badge variant="secondary"> ese + </Badge>
                                                      </h4>
                                                  </a>
                                              </Box>
                                          </Col>
                                      </Row>
                                  </Container>
                              </Card.Body>
                          </Card>
                      </AnimatedCard>
                  ]}
              </PoseGroup>
          </div>
      </div>
  )
}

export default ServicesPanel