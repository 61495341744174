import React from 'react';
import {DefaultRoute} from './_routes/'

const App = () => {
  return (
    <DefaultRoute />
  );
}

export default App;
//TODO
//Refactor all forms components