import React, { useState } from 'react'
import { connect } from 'react-redux'
import PasswordResetForm from './PasswordResetForm'
import CustomNavBar from './CustomNavBar'
import { Redirect } from 'react-router-dom'

const PasswordReset = (props) => {
  const [isVisible, setIsVisible] = useState(false)
  const handleIsVisible = (value) => {
    setIsVisible(value)
  }
  const title = `Restore password form`;
  const subTitle = `Please enter a secure password`;
  const instruction = `*Minimum 8 characters, 1 uppercase, 1 lowecase, 1 number and 1 symbol`
  if (props.isAuthenticated) {
    return <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
  }
  return (
    <div>
      <CustomNavBar auth={props.auth} isAuthenticated={props.isAuthenticated} />
      <PasswordResetForm
        isVisible={isVisible}
        title={title}
        subTitle={subTitle}
        instruction={instruction}
        token={props.match.params.token || ""}
        handleIsVisible={handleIsVisible} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isAuthenticated: state.isAuthenticated
  }
}
export default connect(mapStateToProps)(PasswordReset)