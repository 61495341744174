import {auth} from '../actions/'
let initialState = {
  auth: {}, 
  user: {},
  isAuthenticated: false
}
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case auth.types.LOGGED_IN:
      return {
        auth:action.payload.data,
        isAuthenticated: true
      }      
    case auth.types.LOGGED_OUT:
      return {
        auth: {},
        user: {},
        isAuthenticated: false
      }
    case auth.types.GET_LOGGED_USER_INFO:
      return {
        ...state,
        user: action.payload
      }
    default:
      return state
  }
}

export default rootReducer