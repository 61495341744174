import React, { useState } from "react";
import Tilt from 'react-tilt'
import Swal from 'sweetalert2';
import axios from 'axios';
import { Card, Accordion, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { auth } from '../_redux/actions/'
import { BASE_URL } from '../_helpers/constants'
import { errorMessage } from '../_helpers/axiosErrorsHandler'
import { history } from '../_helpers/history'
import img from '../images/img-01.png';
import useForm from './useForm/useForm';
import validate from './useForm/LoginFormValidationRules';

const Login = (props) => {

    const title = "ese+ connect "
    const subTitle = "Please log in to use our services."
    const [restore, setRestore] = useState()
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        cleanField
    } = useForm(login, validate)

    function login() {
        //console.log('No errors, submit callback called!');
        Swal.fire({
            title: 'Loading ...',
            html: 'Please wait while we validate your credentials',
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
        let axiosOptions = {
            method: 'post',
            url: `${BASE_URL}authentication/token/`,
            headers: { "Content-Type": "application/json" },
            data: {
                username: values.username,
                password: values.password,
                application: 'main'
            }
        }
        axios(axiosOptions)
            .then(response => {
                console.log(response)
                // Save into redux
                props.logIn(response)
                Swal.close()
                if (response.data.is_staff) {
                    //'redirect to admin'
                    history.push('/admin')
                } else {
                    //'redirect to home'
                    history.push('/home')
                }
            })
            .catch(error => {
                let err = errorMessage(error);
                if(err.error){
                    //error from exceptions
                    err = err.error
                }else if(err.status === 0) {
                    err = 'Check internet connection'
                }else{
                    //error from invalid data
                    err = JSON.parse(err.data)
                    err = err.error_description
                }
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    html: `Something went wrong! Try again. <br>${err}`,
                })
            })
    }

    const handleRestoreSubmit = (e) => {
        if (e) e.preventDefault();
        if (values.restore) {
            setRestore(false)
            Swal.fire({
                title: 'Loading ...',
                html: 'Validanting email, please wait a moment... ☕',
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
            let axiosOptions = {
                method: 'post',
                url: `${BASE_URL}password_reset/`,
                headers: { "Content-Type": "application/json" },
                data: {
                    email: values.restore
                }
            }
            axios(axiosOptions)
                .then(response => {
                    if(response.data.status === 'OK'){
                        cleanField('restore')
                        Swal.fire({
                            type: 'success',
                            title: 'Well done!',
                            html: `Please cheack your email to restore your password`,
                        })
                    }else{
                        Swal.close();
                    }
                    
                })
                .catch(error => {
                    let err = errorMessage(error);
                    err = err.email
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        html: `Something went wrong! Try again. <br>${err}`,
                    })
                })
        } else {
            //if restore == true show alerts
            setRestore(true)
        }
    }

    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100" style={{ padding: '15px 125px' }}>
                    <Card.Body>
                        <Card.Title className="text-center">
                            <h1>
                                {title}
                            </h1>
                        </Card.Title>
                        <Card.Text style={{ fontSize: '1.6rem' }} className="text-center">
                            {subTitle}
                        </Card.Text>
                    </Card.Body>
                    <Tilt className="login100-pic Tilt" options={{ max: 50, scale: 1.1 }}>
                        <img className="Tilt-inner" src={img} alt="IMG" />
                    </Tilt>
                    <FormLogin
                        errors={errors}
                        values={values}
                        restore={restore}
                        onChangeHandler={handleChange}
                        onSubmitHandler={handleSubmit}
                        onSubmitRestoreHandler={handleRestoreSubmit}
                    />
                </div>
            </div>
        </div>
    )
}

const FormLogin = (props) => {
    return (
        <form className="login100-form validate-form p-t-50" autoComplete="off">
            <div className={`wrap-input100 validate-input ${props.errors.username && 'alert-validate'}`} data-validate="Username is required">
                <input className="input100" type="text" name="username" placeholder="Username" value={props.values.username || ""} onChange={props.onChangeHandler} />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-user" aria-hidden="true"></i>
                </span>
            </div>
            <div className={`wrap-input100 validate-input ${props.errors.password && 'alert-validate'}`} data-validate="Password is required">
                <input className="input100" type="password" name="password" placeholder="Password" value={props.values.password || ""} onChange={props.onChangeHandler} />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
            </div>
            <div className="container-login100-form-btn m-b-35">
                <button className="login100-form-btn" onClick={(e) => props.onSubmitHandler(e)}>
                    Login
                </button>
                <div className="text-center p-t-12">
                    <Accordion>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0" className="forgot-pw-link">
                            <span className="txt2 forgot-pw-link">
                                Forgot Password?
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <div className={`wrap-input100 validate-input ${props.restore && 'alert-validate'}`} data-validate="Valid email is required">
                                <input className="input100" type="text" name="restore" placeholder="Enter email" value={props.values.restore || ""} onChange={props.onChangeHandler} />
                                <span className="focus-input100"></span>
                                <span className="symbol-input100">
                                    <i className="fa fa-at" aria-hidden="true"></i>
                                </span>
                                <button className="login100-form-btn btn-restore-pw" onClick={(e) => props.onSubmitRestoreHandler(e)}>
                                    <i className="fa fa-retweet" aria-hidden="true"></i>
                                </button>
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                </div>
            </div>
        </form>
    )
}
const mapDispatchToProps = {
    logIn: auth.logIn
};

export default connect(null, mapDispatchToProps)(Login)
//export default Login