import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';
import { PrivateRoute, PublicRoute, NotFound, NotFoundContent, LoginRoute } from './index'
import Login from "../components/Login";
import Main from "../components/Main";
import Admin from "../components/Admin";
import PasswordReset from '../components/PasswordReset'
import PasswordUpdate from '../components/PasswordUpdate';
import { history } from '../_helpers/history';

const DefaultRoute = (props) => {
  
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path="/" isAuthenticated={props.isAuthenticated} auth={props.auth} component={Main} />
        <PrivateRoute path="/home" isAuthenticated={props.isAuthenticated} auth={props.auth} component={Main} />
        <PrivateRoute path="/admin" isAuthenticated={props.isAuthenticated} auth={props.auth}  component={Admin} />
        <PrivateRoute path="/not-found" isAuthenticated={props.isAuthenticated} auth={props.auth} component={NotFoundContent} />
        <PublicRoute  path="/password_reset/:token" isAuthenticated={props.isAuthenticated} component={PasswordReset} />
        <LoginRoute path="/login" isAuthenticated={props.isAuthenticated} auth={props.auth} component={Login} />
        <Route path="/password_update" component={PasswordUpdate} />

        {/* <Route path="/login" component={Login} /> */}
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}
// here rest is mapped to state
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.isAuthenticated, 
    auth: state.auth
  }
}
export default connect(mapStateToProps)(DefaultRoute)