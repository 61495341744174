import React from 'react';
import { Redirect } from 'react-router-dom'

export const NotFound = () => {
  return (
    <Redirect to="/not-found" />
  )
}
export const NotFoundContent = () => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
          <h2>Page not found</h2>
        </div>
        <a href="/">Homepage</a>
      </div>
    </div>
  )
}