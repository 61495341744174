import React, { useEffect, useState } from "react"
import posed from 'react-pose'
import { Card } from 'react-bootstrap'
import Tilt from 'react-tilt'
import Swal from 'sweetalert2'
import axios from 'axios'
import { BASE_URL } from '../_helpers/constants'
import { errorMessage } from '../_helpers/axiosErrorsHandler'
import { history } from '../_helpers/history'
import useForm from './useForm/useForm'
import validate from './useForm/RestorePasswordFormValidationRules'
import img from '../images/key.png'

//Animations
const AnimatedCard = posed.div({
  visible: {
    y: 0,
    opacity: 1,
    delay: 100,
    delayChildren: 200,
    transition: {
      y: { type: 'spring', stiffness: 250, damping: 10 },
      default: { duration: 500 }
    }
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 }
  }
});

const PasswordResetForm = (props) => {
  const [showPasswordText, setShowPasswordText] = useState(false);
  useEffect(() => {
    //To propagate the status change of isVisible status
    props.handleIsVisible(true)
  }, [])
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(login, validate)

  function login() {
    //console.log('No errors, submit callback called!');
    Swal.fire({
      title: 'Loading ...',
      html: 'Please wait, we are updating your password',
      onBeforeOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
    let axiosOptions = {
      method: 'post',
      url: `${BASE_URL}password_reset/confirm/`,
      headers: { "Content-Type": "application/json" },
      data: {
        token: props.token,
        password: values.password
      }
    }
    axios(axiosOptions)
      .then(response => {
        console.log(response)
        Swal.fire({
          type: 'success',
          title: 'Well done!',
          html: `Your password has been updated, you will be redirected to login page`,
        }).then(() => {
          history.push('/login')
        })

      })
      .catch(error => {
        let err = errorMessage(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          html: `Something went wrong! Your token is invalid, <br/>please create a new token in login page.`,
        })
      })
  }

  const showPassword = (e) => {
    if (e) e.preventDefault()
    setShowPasswordText(true)
  }

  const hidePassword = (e) => {
    if (e) e.preventDefault()
    setShowPasswordText(false);
  }

  return (
    <div className="limiter">
      <div className="container-login100">
        <AnimatedCard key="animatedCard" pose={props.isVisible ? 'visible' : 'hidden'}>
          <div className="wrap-login100" style={{ padding: '15px 125px' }}>
            {/* REGISTER USER FORM */}
            <Card.Body >
              <Card.Title className="text-center"> <h1>{props.title}</h1> </Card.Title>
              <Card.Text style={{ fontSize: '1.6rem' }} className="text-center">
                {props.subTitle}
              </Card.Text>
              <h6 className="text-center text-muted">{props.instruction}</h6>
            </Card.Body>
            <Tilt className="login100-pic Tilt" options={{ max: 50, scale: 1.1 }}>
              <img className="Tilt-inner" src={img} alt="IMG" />
            </Tilt>

            <form className="login100-form validate-form m-t-85" autoComplete="off">
              <div className={`wrap-input100 validate-input ${errors.password && 'alert-validate'}`} data-validate={errors.password}>
                <input className="input100" type={showPasswordText ? "text" : "password"} name="password" placeholder="New password" value={values.password || ""} onChange={handleChange} />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
                <span className="login100-form-btn btn-restore-pw" onMouseDown={(e) => showPassword(e)} onMouseUp={(e) => hidePassword(e)}>
                  <i className={showPasswordText ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i>
                </span>
              </div>
              <div className="container-login100-form-btn m-b-150">
                <button className="login100-form-btn" onClick={(e) => handleSubmit(e)}>
                  Restore password
                </button>
              </div>
            </form>
          </div>
        </AnimatedCard>
      </div>
    </div>
  )
}

export default PasswordResetForm