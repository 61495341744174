import React, { useState } from 'react'
import { connect } from 'react-redux'
import PasswordUpdateForm from './PasswordUpdateForm'
import CustomNavBar from './CustomNavBar'
import { auth } from '../_redux/actions/';
import { Redirect } from 'react-router-dom'

const PasswordUpdate = (props) => {
  const [isVisible, setIsVisible] = useState(false)
  const handleIsVisible = (value) => {
    setIsVisible(value)
  }
  const title = `Update password form`;
  const subTitle = `Please enter a secure password`;
  const instruction = `*Minimum 8 characters, 1 uppercase, 1 lowecase, 1 number and 1 symbol`
  if (!props.isAuthenticated) {
    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  }
  return (
    <div>
      <CustomNavBar auth={props.auth} isAuthenticated={props.isAuthenticated} />
      <PasswordUpdateForm
        isVisible={isVisible}
        title={title}
        subTitle={subTitle}
        instruction={instruction}
        token={props.token || ""}
        handleIsVisible={handleIsVisible}
        logOut={props.logOut} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isAuthenticated: state.isAuthenticated,
    token: state.auth.access_token,
  }
}
const mapDispatchToProps = {
  logOut: auth.logOut
};
export default connect(mapStateToProps,mapDispatchToProps)(PasswordUpdate)