import React from "react";
import { useEffect } from 'react';
import posed from 'react-pose';
import { Card } from 'react-bootstrap';
import Swal from 'sweetalert2'
import Tilt from 'react-tilt'
import axios from 'axios'
import { connect } from 'react-redux'
import useForm from './useForm/useForm';
import { BASE_URL } from '../_helpers/constants'
import { errorMessage } from '../_helpers/axiosErrorsHandler'
import validate from './useForm/RegisterFormValidationRules';
import img from '../images/r_user.png'

//Animations
const AnimatedCard = posed.div({
    visible: {
        y: 0,
        opacity: 1,
        delay: 100,
        delayChildren: 200,
        transition: {
            y: { type: 'spring', stiffness: 250, damping: 10 },
            default: { duration: 500 }
        }
    },
    hidden: {
        y: 50,
        opacity: 0,
        transition: { duration: 150 }
    }
});

const RegisterUser = (props) => {
    useEffect(() => {
        //To propagate the status change of isVisible status
        props.handleIsVisible(true)
    }, [])
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(login, validate)

    function login() {
        Swal.fire({
            title: 'Loading ...',
            html: 'Saving new user ... ☕',
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
        let axiosOptions = {
            method: 'post',
            url: `${BASE_URL}authentication/register/`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            },
            data: {
                username: values.username,
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email
            }
        }
        axios(axiosOptions)
            .then(response => {
                Swal.fire({
                    type: 'success',
                    title: 'Well done!',
                    html: `An email was send with login instructions`,
                })

            })
            .catch(error => {
                let err = JSON.stringify(errorMessage(error));
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    html: `Something went wrong! Try again. <br>${err}`,
                })
            })
    }

    return (
        <div className="limiter">
            <div className="container-login100">
                <AnimatedCard key="animatedCard" pose={props.isVisible ? 'visible' : 'hidden'}>
                    <div className="wrap-login100" style={{ padding: '15px 125px', height:'510px' }}>
                        {/* REGISTER USER FORM */}
                        <Card.Body >
                            <Card.Title className="text-center"> <h1>{props.title}</h1> </Card.Title>
                            <Card.Text style={{ fontSize: '1.6rem' }} className="text-center">
                                {props.subTitle}
                            </Card.Text>
                        </Card.Body>
                        <Tilt className="login100-pic Tilt" options={{ max: 50, scale: 1.1 }}>
                            <img className="Tilt-inner" src={img} alt="IMG" />
                        </Tilt>
                        {/* REGISTER USER FORM */}
                        <RegisterUserForm
                            errors={errors}
                            values={values}
                            onChangeHandler={handleChange}
                            onSubmitHandler={handleSubmit} />
                    </div>
                </AnimatedCard>
            </div>
        </div>
    )
}

const RegisterUserForm = (props) => {
    return (
        <form className="login100-form validate-form" autoComplete="off">
            <div className={`wrap-input100 validate-input ${props.errors.username && 'alert-validate'}`} data-validate="Username is required">
                <input className="input100" type="text" name="username" placeholder="Username" value={props.values.username || ""} onChange={props.onChangeHandler} />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-user" aria-hidden="true"></i>
                </span>
            </div>
            <div className={`wrap-input100 validate-input ${props.errors.firstName && 'alert-validate'}`} data-validate="First name is required">
                <input className="input100" type="text" name="firstName" placeholder="First name" value={props.values.firstName || ""} onChange={props.onChangeHandler} />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-edit" aria-hidden="true"></i>
                </span>
            </div>
            <div className={`wrap-input100 validate-input ${props.errors.lastName && 'alert-validate'}`} data-validate="Last name is required">
                <input className="input100" type="text" name="lastName" placeholder="Last name" value={props.values.lastName || ""} onChange={props.onChangeHandler} />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-edit" aria-hidden="true"></i>
                </span>
            </div>
            <div className={`wrap-input100 validate-input ${props.errors.email && 'alert-validate'}`} data-validate="email is required">
                <input className="input100" type="text" name="email" placeholder="Email" value={props.values.email || ""} onChange={props.onChangeHandler} />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
            </div>
            <div className="container-login100-form-btn m-b-150">
                <button className="login100-form-btn" onClick={(e) => props.onSubmitHandler(e)}>
                    Login
                </button>
            </div>
        </form>
    )
}
const mapStateToProps = (state) => {
    return {
        token: state.auth.access_token,
    }
}
export default connect(mapStateToProps)(RegisterUser)