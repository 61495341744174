import React, {useState} from 'react'
import { connect } from 'react-redux'
import CustomNavBar from './CustomNavBar'
import RegisterUser from  './RegisterUser'

const Admin = (props) => {
  const [isVisible, setIsVisible] = useState(false)
  const handleIsVisible = (value) =>{
      setIsVisible(value)
  }
  const title = `Register a new user`;
  const subTitle = `All fields are requiered*`;
  return (
    <div>
      <CustomNavBar auth={props.auth} isAuthenticated={props.isAuthenticated}/>
      <RegisterUser 
        isVisible={isVisible} 
        title={title} 
        subTitle={subTitle} 
        handleIsVisible={handleIsVisible}/>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isAuthenticated: state.isAuthenticated
  }
}
export default connect(mapStateToProps)(Admin)