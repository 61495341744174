import React from 'react';
import { Route, Redirect } from 'react-router-dom';

//rest are the props inherit from parent component
const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      return <Component {...props} />      
    }}
    />
  )
}

export default PublicRoute
