const validate = (values) => {
    let errors = {};
    if (!values.username) {
        errors.username = 'Username is required';
    }
    if (!values.firstName) {
        errors.firstName = 'First name is required';
    }
    if (!values.lastName) {
        errors.lastName = 'Last name is required';
    }
    if (!values.email) {
        errors.email = 'email is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }
    return errors;
};

export default validate