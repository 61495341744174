import React from "react";
import CustomNavBar from './CustomNavBar';
import ServicesPanel from './ServicesPanel'
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { auth } from '../_redux/actions/';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL } from '../_helpers/constants';

const Main = (props) => {
    const [isVisible, setIsVisible] = useState(false)
    const handleIsVisible = (value) =>{
        setIsVisible(value)
    }
    useEffect(() => {
        if (!props.user) {
            Swal.fire({
                title: 'Loading ...',
                html: 'Please wait a moment',
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
            let axiosOptions = {
                method: 'get',
                url: `${BASE_URL}authentication/me/`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                },
            }
            axios(axiosOptions)
                .then(response => {
                    console.log(response.data)
                    // Save into redux
                    props.getLoggedUserInfo(response.data)
                    Swal.close()
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        html: `Something went wrong! Try again. <br>${error}`,
                    })
                })
                .finally(() => {
                    setIsVisible(true)
                })
        }
    }, []);

    const title = props.user
        ? ` Welcome ${props.user.first_name} ${props.user.last_name}!`
        : ` Welcome`
    const subTitle = `Please visit our other awesome services 😎`;

    return (
        <div>
            <CustomNavBar auth={props.auth} isAuthenticated={props.isAuthenticated}/>
            <ServicesPanel title={title} subTitle={subTitle} isVisible={isVisible} handleIsVisible={handleIsVisible} hasUser={props.user ? true : false}/>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        token: state.auth.access_token,
        user: state.user, 
        auth: state.auth,
        isAuthenticated: state.isAuthenticated
    }
}
const mapDispatchToProps = {
    getLoggedUserInfo: auth.getLoggedUserInfo
};
export default connect(mapStateToProps, mapDispatchToProps)(Main)